import React, { useCallback, useMemo, useRef } from 'react';

import TextField from '@mui/material/TextField';
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker/TimePicker';
import { TimePickerProps } from '@mui/x-date-pickers/TimePicker/TimePicker.types';
import cn from 'classnames';
import { format, setHours, setMinutes, setSeconds } from 'date-fns';

import { dateFormats } from 'utils/dateUtil';

import styles from './TimePicker.module.scss';

interface Props extends Omit<TimePickerProps<Date>, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  'data-testid'?: string;
  testId?: string;
  error?: boolean;
  helperText?: string;
  formMargin?: any;// not used
}

const convertTimeToDateFormat = (timeString?: string) => {
  if(!timeString) return null;

  const [hours = 0, minutes = 0, seconds = 0] = timeString.split(':').map(Number);
  const today = new Date(); // You can replace this with your own date

  return setSeconds(setMinutes(setHours(today, hours), minutes), seconds);
}

const convertDateToTimeFormat = (date: Date | null) => {
  if (!date) return '';

  return format(date, dateFormats.time);
}

const TimePicker = ({onChange, className, label, readOnly, value: valueProp, ...props}: Props) => {

  const inputRef = useRef<any>(null);
  const value = useMemo(() => convertTimeToDateFormat(valueProp), [valueProp]);

  const handleChange = useCallback((value: Date | null) => {
    const time = convertDateToTimeFormat(value);

    onChange?.(time);
  },[onChange])

  // console.log(inputRef);

  if(inputRef.current) {
    inputRef.current.setAttribute('data-testid', props?.['data-testid']);

    // console.log(inputRef.current);
  }

  return (
    <TimePickerMUI
      label={label}
      className={cn(styles.component, className)}
      value={value}
      onChange={handleChange}
      onAccept={handleChange}
      ampm={false}
      slotProps={{
        popper: {
          className: styles.popper,
        },
        textField: {
          // fullWidth: fullWidth,
          // variant: 'outlined',
          error: props.error,
          helperText: props.helperText,
        },
      }}
      readOnly={readOnly}
      disabled={readOnly}
      {...props}
      inputRef={inputRef}
    />
  );
}

export default TimePicker;
// https://mui.com/x/api/date-pickers/time-picker/
