import { useAuth } from 'components/pages/Auth/AuthProvider';
import { UserRoleType } from 'components/types';
import { useCompanies } from 'providers/CompaniesProvider';
import { useOrganization } from 'providers/OrganizationProvider';


export const getRoles = (roles?: UserRoleType[]) => {
  if (!roles) return {
    isAdmin: false,
    isEmployee: false,
    isAdministrator: false,
    isOwner: false,
  }
  const isAdmin = Boolean(roles.find((role) => role.name === 'admin'));
  const isEmployee = Boolean(roles.find((role) => role.name === 'employee'));
  const isEmployeeOnly = isEmployee && roles.length === 1;
  const isAdministrator = Boolean(roles.find((role) => role.name === 'company_administrator')) || Boolean(roles.find((role) => role.name === 'moderator'));
  const isOwner = Boolean(roles.find((role) => role.name === 'owner'));

  return {
    isAdmin,
    isEmployee,
    isEmployeeOnly,
    isAdministrator,
    isOwner,
    isManagement: isAdministrator || isOwner || isAdmin,
  }
}


const useAccount = () => {
  const {user} = useAuth();
  const {organization} = useOrganization();
  const {company} = useCompanies();

  const roleInCompany = user?.roles.filter(item => item?.pivot?.companyId === company?.id)?.[0];
  const rolesByCompany = roleInCompany ? [roleInCompany] : undefined;
  const rolesByOrganization = user?.roles.filter(item => item?.pivot?.organizationId === organization?.id);

  // console.log({rolesByCompany, rolesByOrganization});
  // export type UserRoleNames = 'admin' | 'owner' | 'company_administrator' | 'moderator' | 'employee' | 'user';
  return {
    user,
    company: getRoles(rolesByCompany),
    organization: getRoles(rolesByOrganization),
    getRoles,
  }

};

export default useAccount;
